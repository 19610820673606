import React from "react";

const Subscribe = () => {
  return (
    <section className="mailchimp-one" id="subscribe">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            <span>Contact</span> Us
          </h2>
        </div>
        <div className="subscribe-section">
          <h4 >
            Address: 
          </h4>
          <p>
          City of Sofia, Triaditsa Region, 9 Pozitano Str., fl. 1, ap. Shop 15, Post code 1000
          </p>
          <p>E-Mail: <a href={`mailto:"support@btcrukainvestment.com"`}>  support@btcrukainvestment.com</a></p>
        </div>
        {/* <label
          className="mailchimp-one__mc-form mc-form"
          data-url="#"
        >
          <label htmlFor="mc-email" className="sr-only">
            Add email
          </label>
          <input
            type="email"
            id="mc-email"
            className="formInput"
            placeholder="Email address"
          />
          <button type="submit">
            <i className="dimon-icon-right-arrow"></i>
          </button>
        </label>
        <div className="mc-form__response"></div> */}
      </div>
    </section>
  );
};
export default Subscribe;
