import React from "react";

const Features = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            <span>Services</span> <br />
          </h2>
        </div>
        <div className="row services-row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-laptop"></i>
                {/* <h3>
                  <a href="#none">
                    Speed <br /> Optimization
                  </a>
                </h3> */}
                <p>
                Our skilled <br /> software team enhances your <br />financial services for accessibility and efficiency.
                </p>
                {/* <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-presentation"></i>
                {/* <h3>
                  <a href="#none">
                    Marketing <br /> Analysis
                  </a>
                </h3> */}
                <p>
                Boost your financial operations  <br />with our practical solutions, <br />eliminating delays and increasing efficiency.
                </p>
                {/* <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a> */}
              </div>
            </div>
          </div>
          {/* <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          > */}
            {/* <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-target"></i>
                <h3>
                  <a href="#none">
                    SEO and <br /> Backlinks
                  </a>
                </h3>
                <p>
                  Lorem ipsum is are <br /> many variations of <br /> pass of
                  majority.
                </p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div> */}
          {/* </div> */}
          {/* <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          > */}
            {/* <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-visualization"></i>
                <h3>
                  <a href="#none">
                    Content <br /> Marketing
                  </a>
                </h3>
                <p>
                  Lorem ipsum is are <br /> many variations of <br /> pass of
                  majority.
                </p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
};
export default Features;
