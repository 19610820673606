import React from "react";
import bgImage from "../assets/images/background/cta-one-bg.png";
import mocImage from "../assets/images/mocs/cta-moc-1-1.png";
const CallToAction = () => {
  return (
    <section className="cta-one" id="about-us">
      <img src={bgImage} className="cta-one__bg" alt="alter text" />
      <div className="container">
        <img src={mocImage} className="cta-one__moc" alt="alter text" />
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              {/* <i className="cta-one__icon dimon-icon-data1"></i> */}
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  About  <span>Us</span>
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  Experience the excellence of Btc Ruka, an esteemed company renowned for delivering turnkey projects in the realms of business process management, outsourcing, and harnessing business intelligence.
                </p>
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i>Our dedicated team offers top-notch software solutions and expert consultancy services.
                </li>
                <li>
                  <i className="fa fa-check"></i>We optimize business processes, streamline outsourcing strategies, and leverage business intelligence tools.
                </li>
                <li>
                  <i className="fa fa-check"></i>Years of experience and a proven track record make us a reliable solution partner for enhanced organizational efficiency.
                </li>
              </ul>
              {/* <a href="#none" className="thm-btn">
                <span>Get Started</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
